


























































































































































































import DataDashboardSidepanel from '@/components/datadashboardcomponent/datadashboardsidepanel/DataDashboardSidepanel';
export default DataDashboardSidepanel;
